import { Plugin } from '@uppy/core';
import UploadsAdapter from "../UploadsAdapter";
export default class UppyFormPlugin extends Plugin {
    formFieldTarget;
    getUploadedFileDataById;
    addFileFromForm;
    constructor(uppy, opts) {
        super(uppy, opts);
        console.warn('@deprecated to be removed', 'assets/plugins/upload-manager/plugin/UppyFormPlugin.ts:24');
        this.id = opts.id;
        this.uppy = uppy;
        this.type = 'form-modifier';
        this.formFieldTarget = opts.formFieldTarget;
        this.getUploadedFileDataById = opts.getUploadedFileDataById;
        this.addFileFromForm = opts.addFileFromForm;
    }
    install() {
        if (!this.formFieldExists()) {
            console.error(`Form field not found using provided selector: ${this.formFieldTarget}`);
        }
        else {
            this.initDataFromFormField();
            this.uppy.addPostProcessor(this.appendUploadedResourceToFormField);
            this.uppy.on('file-added', this.disablePreFilledFilesFromUploading);
            this.uppy.on('file-removed', this.removeFileFromForm);
        }
    }
    uninstall() {
        this.uppy.removePostProcessor(this.appendUploadedResourceToFormField);
        this.uppy.off('file-added', this.disablePreFilledFilesFromUploading);
        this.uppy.off('file-removed', this.removeFileFromForm);
    }
    initDataFromFormField = () => {
        const data = this.getCurrentDataFromFormField();
        data.forEach((resourceData) => {
            const httpRequest = this.prepareAndGetRequestForResource(resourceData.fileNameOnServer);
            httpRequest.onload = () => {
                const blob = httpRequest.response;
                this.addBlobFileToUppy(blob, resourceData);
            };
            httpRequest.send();
        });
    };
    disablePreFilledFilesFromUploading = () => {
        const files = this.uppy.getFiles();
        files.forEach((file) => {
            if (file.source === "Form") {
                this.uppy.setFileState(file.id, {
                    progress: {
                        ...file.progress,
                        bytesUploaded: file.progress.bytesTotal,
                        uploadComplete: true,
                        uploadStarted: true
                    }
                });
            }
        });
    };
    appendUploadedResourceToFormField = async (fileIds) => {
        const data = this.getCurrentDataFromFormField();
        fileIds.forEach((fileId) => {
            const uploadedFile = this.getUploadedFileDataById(fileId);
            if (uploadedFile) {
                data.push({
                    fileName: uploadedFile.file.name,
                    fileNameOnServer: uploadedFile.nameOnServer
                });
            }
        });
        this.saveDataToFormField(data);
    };
    getCurrentDataFromFormField = () => {
        const currentData = $(this.formFieldTarget).val();
        const currentDataParsed = [];
        if (currentData) {
            try {
                currentDataParsed.push(...JSON.parse(currentData));
            }
            catch (e) { }
        }
        return currentDataParsed;
    };
    saveDataToFormField = (data) => {
        $(this.formFieldTarget).val(data.length !== 0 ? JSON.stringify(data) : undefined);
    };
    removeFileFromForm = (file, reason) => {
        if (reason === 'removed-by-user') {
            const fileId = file.id;
            const uploadedFile = this.getUploadedFileDataById(fileId);
            if (uploadedFile) {
                const data = this.getCurrentDataFromFormField();
                const filteredData = data.filter((resourceData) => {
                    return resourceData.fileName !== uploadedFile.file.name
                        && resourceData.fileNameOnServer !== uploadedFile.nameOnServer;
                });
                this.saveDataToFormField(filteredData);
            }
        }
    };
    prepareAndGetRequestForResource = (resource) => {
        const getResourceRoute = UploadsAdapter.generateGetResourceRouteForResource(resource);
        const httpRequest = new XMLHttpRequest();
        httpRequest.open("GET", getResourceRoute, true);
        httpRequest.responseType = "blob";
        return httpRequest;
    };
    addBlobFileToUppy = (blob, resourceData) => {
        if (blob instanceof Blob) {
            this.uppy.setOptions({ autoProceed: false });
            const fileID = this.uppy.addFile({
                name: resourceData.fileName,
                type: blob.type,
                data: blob,
                source: "Form",
                progress: {
                    bytesTotal: blob.size,
                    bytesUploaded: blob.size,
                    percentage: 100,
                    uploadComplete: true,
                    uploadStarted: 1
                },
                meta: {
                    name: resourceData.fileName,
                    type: blob.type
                },
            });
            this.addFileFromForm(fileID, {
                file: this.uppy.getFile(fileID),
                nameOnServer: resourceData.fileNameOnServer
            });
            this.uppy.setOptions({ autoProceed: true });
        }
    };
    formFieldExists = () => {
        return $(this.formFieldTarget).length !== 0;
    };
}
