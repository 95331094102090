"use strict";

/**
 * Define the output of this file. The output of CSS and JS file will be auto detected.
 *
 * @output plugins/global/plugins.bundle
 */


//** Begin: Global mandatory plugins
window.jQuery = window.$ = require("jquery");
require("bootstrap");
require("block-ui");
window.moment = require("moment");
window.Cookies = require("js-cookie");
require("jquery-form");


// Perfect-Scrollbar
require("perfect-scrollbar/css/perfect-scrollbar.css");
window.PerfectScrollbar = require("perfect-scrollbar/dist/perfect-scrollbar");
//** End: Globally mandatory plugins


//** Begin: Global optional plugins
// Daterangepicker
require("bootstrap-daterangepicker/daterangepicker.css");
require("bootstrap-daterangepicker");

// Bootstrap-Select
require("bootstrap-select/dist/css/bootstrap-select.css");
require("bootstrap-select");
require("ajax-bootstrap-select");

// Sweetalert2
require("sweetalert2/dist/sweetalert2.css");
import swal from "sweetalert2/dist/sweetalert2";
window.swal = swal;
require("es6-promise-polyfill/promise.min.js");
require("../../../src/assets/js/global/integration/plugins/sweetalert2.init");

// Bootstrap-Notify
require("bootstrap-notify");
require("../../../src/assets/js/global/integration/plugins/bootstrap-notify.init.js");

// Bootstrap-Datepicker
require("bootstrap-datepicker/dist/css/bootstrap-datepicker3.css");
require("bootstrap-datepicker");
require("../../../src/assets/js/global/integration/plugins/bootstrap-datepicker.init");

// Font Icons
require("../../../src/assets/plugins/line-awesome/css/line-awesome.css");
require("@fortawesome/fontawesome-free/css/all.min.css");
//** End: Global optional plugins
