// jquery
import '../theme/tools/webpack/vendors/global';

// non-theme vendors
import '../theme/src/assets/sass/pages/wizards/wizard-v2.scss';
import '../theme/src/assets/sass/pages/wizards/wizard-v3.scss';
import '../sass/fwc-listing.scss';
import '../sass/administration.scss';

// Uppy styles
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

// custom plugins
import "../plugins/route-generator/routeGenerator";
import '../plugins/conditional-form-elements/conditionalFormElement';
import "../plugins/createable-entity/createableEntity";
import '../plugins/upload-manager/UploadsAdapter';

import '../plugins/document-generator/documentGenerator';

// additional scripts
import './main.js';

import Glide from '@glidejs/glide';

import axios from "axios";
// translations
import {Translator, getLocale, isLocale, setLocale,} from 'Services/Translator';

import {loadTranslations} from "./translations";

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.jQuery = window.$ = require("jquery");
window.Glide = Glide;

// custom styles
require('../sass/app.scss');

setLocale(document.getElementsByTagName('html')[0].getAttribute('lang'));
loadTranslations(getLocale());

window.Translator = Translator;

if (isLocale('de')) {
    require('bootstrap-select/js/i18n/defaults-de_DE');
}

$(document).ajaxError(function (event, xhr) {
    const json = xhr.responseJSON;
    if (!!json && !!json.redirectUrl) {
        window.location = json.redirectUrl;
    }
});


$(document).ready(function() {
    $('input.custom-file-input[type="file"]').on("change", function() {
        const files = this.files;
        let filenames = [];

        for (let i in files) {
            if (files.hasOwnProperty(i)) {
                filenames.push(files[i].name);
            }
        }

        const maxNames = 3;
        const nbFiles = files.length;
        let nbOtherFiles = Math.max(0, nbFiles - maxNames);

        if (nbFiles > maxNames) {
            filenames = filenames.slice(0, maxNames);
        }

        $(this)
            .next(".custom-file-label")
            .html(filenames.join(", ") + (nbOtherFiles > 0 ? ` [+${nbOtherFiles} more]` : ''));
    });
});
