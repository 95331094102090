import * as $ from "jquery";
import Translator from "bazinga-translator";
$.fn.createableEntity = function (options) {
    const settings = {
        ...options
    };
    return this.each(function () {
        const $eSelect = $(this).find('.creatable-entity--existing').first();
        const $eNewForm = $(this).find('.creatable-entity--new').first();
        const $eFlag = $(this).find('.creatable-entity--is-created').first();
        initCreatableEntity($eSelect, $eNewForm, $eFlag);
    });
};
export const initCreatableEntityFromSelector = (elementSelector) => {
    const $eSelect = $(elementSelector).find('.creatable-entity--existing').first();
    const $eNewForm = $(elementSelector).find('.creatable-entity--new').first();
    const $eFlag = $(elementSelector).find('.creatable-entity--is-created').first();
    initCreatableEntity($eSelect, $eNewForm, $eFlag);
};
const initCreatableEntity = ($eSelect, $eNewForm, $eFlag) => {
    $eFlag.val('0');
    $eSelect.find('button').on('click', function () {
        if (!$eSelect.closest('.creatable-entity').hasClass('new-bank-account-root')) {
            $eNewForm.modal('show');
        }
    });
    $('#new-bank-account-added').on('click', function (event) {
        if (typeof event.detail === 'object') {
            const newObject = event.detail;
            const label = "Neu " + newObject.label;
            $eSelect.find('select').append(`<option value="${newObject.id}">${label}</option>`);
            $eSelect.find('select').val(newObject.id);
            $eSelect.find('.selectpicker').selectpicker('refresh');
        }
    });
    const selectId = $eSelect.find('select').attr("id");
    if (!(selectId.endsWith("BankAccount_entity") || selectId.endsWith("bankAccount_entity"))) {
        $eNewForm.find('.btn-save').on('click', () => {
            let hasErrors = false;
            Array.prototype.forEach.call(document.getElementsByClassName($eNewForm.attr('class'))[0].querySelectorAll("[required]"), function (el) {
                if (el.offsetParent === null) {
                    return false;
                }
                if (el.reportValidity() === false) {
                    hasErrors = true;
                }
            });
            if (hasErrors) {
                return false;
            }
            const labelElement = $eNewForm.find('.creatable-entity--label');
            let label = "Neu";
            if (labelElement.length !== 0) {
                label = `${$eNewForm.find('.creatable-entity--label').val().toString()} (${Translator.trans("new", {}, "messages")})`;
            }
            $eFlag.val('1');
            $eSelect.find('select').append('<option value="-1">' + label + '</option>');
            $eSelect.find('select').val("-1");
            $eSelect.find('.selectpicker').selectpicker('refresh');
            $eNewForm.modal('hide');
        });
    }
};
