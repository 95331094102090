import {getGlobalContext} from "Services/GlobalContext";
import generateRoute from "Services/Router";
import {translate, updateLocale} from 'Services/Translator';

/**
 * Cache structure:
 * fw_i18n[messages]{version: string, data: json}
 * fw_i18n[administration]{version: string, data: json}
 */

const TRANSLATIONS_KEY = 'fw_i18n';

const getTranslationCacheKey = (locale, domain) => {
    const {appVersion} = getGlobalContext();
    return `${locale}_${domain}_${appVersion}`;
};

const getCachedTranslations = (locale, domain) => {
    const cachedTranslationsRaw = localStorage.getItem(TRANSLATIONS_KEY);
    if (!cachedTranslationsRaw) {
        return null;
    }

    try {
        const cachedTranslations = JSON.parse(cachedTranslationsRaw);
        if (!cachedTranslations[domain]) {
            return null;
        }

        const key = getTranslationCacheKey(locale, domain);
        if (key !== cachedTranslations[domain].version) {
            return null;
        }

        return cachedTranslations[domain].data;
    } catch (e) {
        console.error(e);
        return null;
    }
};

const setCachedTranslations = (locale, domain, translations) => {
    let cachedTranslations;
    try {
        cachedTranslations = JSON.parse(
            localStorage.getItem(TRANSLATIONS_KEY) || "{}"
        );
    } catch (e) {
        cachedTranslations = {};
    }

    cachedTranslations[domain] = {
        version: getTranslationCacheKey(locale, domain),
        data: translations
    };

    localStorage.setItem(TRANSLATIONS_KEY, JSON.stringify(cachedTranslations));
};

const fetchTranslations = (locale, domain, callback) => {
    const route = generateRoute('bazinga_jstranslation_js', {
        '_format': 'json',
        'domain': domain,
        'locales': locale
    });

    $.ajax({
        url: route,
        dataType: 'json',
        async: false, //need JQuery to make this magic work
        success: callback,
    });
};

const updateDataTableTranslations = () => {
    localStorage.setItem('dataTableTranslation', JSON.stringify({
        records: {
            processing: translate('table.processing', {}, 'messages'),
            noRecords: translate('table.noRecords', {}, 'messages'),
        },
        toolbar: {
            pagination: {
                items: {
                    default: {
                        first: translate('table.firstPage', {}, 'messages'),
                        prev: translate('table.previousPage', {}, 'messages'),
                        next: translate('table.nextPage', {}, 'messages'),
                        last: translate('table.lastPage', {}, 'messages'),
                        more: translate('table.moreRecords', {}, 'messages'),
                        input: translate('table.page', {}, 'messages'),
                        select: translate('table.recordsPerPage', {}, 'messages'),
                        all: translate('table.all', {}, 'messages')
                    },
                    info: translate('table.info', {}, 'messages')
                },
            },
        },
    }));
};

const populateTranslator = (domain, locale) => {
    let translations = getCachedTranslations(locale, domain);

    if (translations) {
        updateLocale(locale, translations);
    } else {
        fetchTranslations(locale, domain, (translations) => {
            setCachedTranslations(locale, domain, translations);
            updateLocale(locale, translations);

            if (domain === 'messages') {
                updateDataTableTranslations();
            }
        });
    }
};

export const loadTranslations = (locale) => {
    populateTranslator('messages', locale);
    populateTranslator('documents', locale);

    if (window.location.pathname.match(/^\/administration/)) {
        populateTranslator('administration', locale);
    }
};
