import { Router } from "../route-generator/routeGenerator";
import UppyUploader from "./uploader/UppyUploader";
window.initUploadsAdapter = (uploadUrl) => {
    window.UPLOADS_ADAPTER = new UploadsAdapter(new UppyUploader(uploadUrl), uploadUrl);
};
export default class UploadsAdapter {
    uploader;
    uploadResourceUrl;
    constructor(uploader, uploadResourceUrl) {
        this.uploader = uploader;
        this.uploadResourceUrl = uploadResourceUrl;
        console.warn('@deprecated to be removed', 'assets/plugins/upload-manager/UploadsAdapter.ts:22');
    }
    initUploader = (config) => {
        this.uploader.init(config);
    };
    static generateDeleteResourceRouteForResource = (resourceToDelete) => {
        return Router.generate("delete_resource", { resourceName: resourceToDelete });
    };
    static generateGetResourceRouteForResource = (resourceToGet) => {
        return `/uploads/${resourceToGet}`;
    };
}
;
