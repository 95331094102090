import * as $ from "jquery";
import { Router } from 'Services/Router';
export { Router };
$.fn.generateRoute = function (options) {
    console.warn('@deprecated to be removed', 'assets/plugins/route-generator/routeGenerator.ts:22');
    return Router.generate({
        route: options?.route,
        locale: options?.locale,
        params: options?.routeOptions,
    });
};
