require('../../public/js/jquery.collection');

function colorAvatars() {
    function stringToHslColor(str, s, l) {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }

        var h = hash % 360;
        return 'hsl('+h+', '+s+'%, '+l+'%)';
    }

    $(".kt-random-color").each((i, e) => {
        const backgroundColor = stringToHslColor($(e).data('name'), 30, 80);
        const textColor = stringToHslColor($(e).data('name'), 30, 40);
        $(e).css('color', textColor);
        $(e).css('background-color', backgroundColor);
    });
}

function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}
window.copyTextToClipboard = function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(function() {
        console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
        console.error('Async: Could not copy text: ', err);
    });
}

function initSelectPicker()
{
    $('[data-toggle="popover"]').popover();
    $('.kt_selectpicker').selectpicker();

    $('select.kt_selectpicker_ajax')
        .selectpicker({
            liveSearch: true
        })
        .ajaxSelectPicker({
        })
    ;
}

$(document).ready(() => {
    initSelectPicker();
    colorAvatars();
    $("[data-conditional-discriminator]").conditionalFormElements();

    const $collections = $(".collection");
    if ($collections.length > 0) {
        $collections.collection();
    }

    $('form :submit').on("click", function () {
        const $form = $(this).closest('form');

        $("input[required]:hidden", $form).each(function() {
            $(this).removeAttr("required");
        });
    });
});
