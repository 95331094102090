import * as $ from "jquery";
$.fn.conditionalFormElements = function (options) {
    const settings = {
        ...options
    };
    const updateView = (context, discriminatorField) => {
        const discriminatorValue = discriminatorField.val();
        const values = $(discriminatorField).find("option").map((i, v) => v.value);
        values.each((i, v) => {
            if ($(context).data('conditional-value-' + v) !== undefined) {
                if (discriminatorValue === v) {
                    const $activeFieldSet = $(this).find('div[id$=\'' + $(context).data('conditional-value-' + v) + '\']').closest("fieldset");
                    $activeFieldSet.removeAttr("validate");
                    $('input', $activeFieldSet).each(function () {
                        const $input = $(this);
                        if ($input.data("required")) {
                            $input.attr("required", "required");
                        }
                    });
                    $activeFieldSet.show();
                }
                else {
                    const $inactiveFieldSet = $(this).find('div[id$=\'' + $(context).data('conditional-value-' + v) + '\']').closest("fieldset");
                    $inactiveFieldSet.attr("validate", "novalidate");
                    $('input', $inactiveFieldSet).each(function () {
                        const $input = $(this);
                        if ($input.attr("required")) {
                            $input.data("required", "required");
                            $input.removeAttr("required");
                        }
                    });
                    $inactiveFieldSet.hide();
                }
            }
        });
    };
    return this.each(function () {
        const discriminatorField = $(this).find('select[id$=\'' + $(this).data('conditional-discriminator') + '\']');
        let that = this;
        discriminatorField.on('change', function (e) {
            updateView(that, discriminatorField);
        });
        discriminatorField.trigger('change');
    });
};
