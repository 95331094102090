import { Uppy } from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import XHRUpload from "@uppy/xhr-upload";
import UploadsAdapter from "../UploadsAdapter";
import German from "@uppy/locales/lib/de_DE";
import English from "@uppy/locales/lib/en_US";
import Translator from "bazinga-translator";
import UppyFormPlugin from "../plugin/UppyFormPlugin";
export default class UppyUploader {
    files = new Map();
    uppyInstance;
    uploadUrl;
    uppyLocale = getUppyTranslationsByTranslatorLocale();
    uppyBaseConfig = {
        id: 'uppy',
        restrictions: {
            maxFileSize: 8388608
        },
        locale: this.uppyLocale
    };
    dashboardBaseConfig = {
        width: "100%",
        inline: true,
        showProgressDetails: true,
        hideCancelButton: true,
        hideRetryButton: true,
        hidePauseResumeButton: true,
        hideUploadButton: true,
        showRemoveButtonAfterComplete: true,
        doneButtonHandler: null,
        locale: this.uppyLocale
    };
    formBaseConfig = {
        id: "uppy-form-plugin",
        getUploadedFileDataById: (fileID) => {
            return this.files.get(fileID);
        },
        addFileFromForm: (fileID, uploadedFile) => {
            this.files.set(fileID, uploadedFile);
        }
    };
    currentConfig;
    constructor(uploadUrl) {
        this.uploadUrl = uploadUrl;
        console.warn('@deprecated to be removed', 'assets/plugins/upload-manager/uploader/UppyUploader.ts:70');
    }
    init = (config) => {
        this.currentConfig = config;
        this.uppyInstance = new Uppy({
            ...this.uppyBaseConfig,
            autoProceed: config.autoProceed,
            restrictions: {
                ...this.uppyBaseConfig.restrictions,
                ...config.restrictions
            }
        });
        if (config.useDashboard) {
            this.appendDashboardPlugin(config);
        }
        if (config.formData) {
            this.appendFormPlugin(config);
        }
        this.appendXHRUploadPlugin();
        this.uppyInstance.on('file-removed', this.handleDeletedResource(config.preventDeletingFilesFromServer));
        this.uppyInstance.on('upload-success', this.handleUploadedResource);
    };
    appendDashboardPlugin = (config) => {
        if (!this.uppyInstance) {
            return;
        }
        this.uppyInstance.use(Dashboard, {
            ...this.dashboardBaseConfig,
            target: config.target,
            hideUploadButton: config.autoProceed
        });
    };
    appendFormPlugin = (config) => {
        if (!this.uppyInstance) {
            return;
        }
        this.uppyInstance.use(UppyFormPlugin, {
            ...this.formBaseConfig,
            formFieldTarget: config.formData.formField
        });
    };
    appendXHRUploadPlugin = () => {
        if (!this.uppyInstance) {
            return;
        }
        this.uppyInstance.use(XHRUpload, {
            endpoint: this.uploadUrl
        });
    };
    handleUploadedResource = (file, response) => {
        const uploadedFile = {
            nameOnServer: response.body.filename,
            file: file
        };
        this.files.set(file.id, uploadedFile);
    };
    handleDeletedResource = (preventDeletingFilesFromServer = false) => async (file, reason) => {
        if (reason === 'removed-by-user') {
            const fileId = file.id;
            const uploadedFile = this.files.get(fileId);
            if (uploadedFile) {
                if (!preventDeletingFilesFromServer) {
                    await this.deleteFileFromServer(uploadedFile.nameOnServer);
                }
                this.files.delete(fileId);
            }
        }
    };
    deleteFileFromServer = async (nameOnServer) => {
        const deleteResourceRoute = UploadsAdapter.generateDeleteResourceRouteForResource(nameOnServer);
        await $.ajax({ url: deleteResourceRoute, type: 'DELETE' });
    };
}
const getUppyTranslationsByTranslatorLocale = () => {
    switch (Translator.locale) {
        case "de": return German;
        case "en": return English;
        default: return English;
    }
};
