import Translator from 'bazinga-translator';
export default class DocumentGenerator {
    constructor() {
        this.attach();
        console.warn('@deprecated to be removed', 'assets/plugins/document-generator/documentGenerator.ts:13');
    }
    attach() {
        const dataGenerateDocumentSelector = $('[data-generate-document]');
        dataGenerateDocumentSelector.off('click');
        dataGenerateDocumentSelector.on('click', (event) => {
            event.preventDefault();
            event.stopPropagation();
            this.handleRequest(event.currentTarget, event.altKey);
        });
    }
    handleRequest(element, rawFormat = false) {
        const format = rawFormat || element.getAttribute('data-format') ? 'html' : 'pdf';
        return this.generateDocument(element.getAttribute('data-generate-document'), element.getAttribute('data-source'), element.getAttribute('data-source-id'), format, !!element.getAttribute('data-source-mandated'));
    }
    generateDocument(template, sourceClass, sourceId, format = "pdf", mandated = false) {
        const payload = {
            template,
            sourceClass,
            sourceId,
            format,
            mandated
        };
        const generator = window.Router.generate('helper_generate_document', payload);
        let generateDocumentCall = $.ajax({
            type: "GET",
            url: generator,
            success: (data) => {
                let fileLink = document.createElement('a');
                fileLink.href = data;
                fileLink.click();
                swal.close();
            }
        }).fail((response) => {
            swal.close();
            if (response.status !== 0) {
                swal.fire({
                    title: Translator.trans("popup.documents.error.title", {}, "messages"),
                    text: Translator.trans("popup.documents.error.couldNotBeGenerated", {}, "messages"),
                    icon: 'error',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: Translator.trans("btn.close", {}, "messages")
                }).then(function () { });
            }
        });
        swal.fire({
            title: Translator.trans("popup.documents.info.title", {}, "messages"),
            text: Translator.trans("popup.documents.info.pleaseWait", {}, "messages"),
            icon: 'info',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: Translator.trans("btn.cancel", {}, "messages"),
            onClose: function () {
                generateDocumentCall.abort();
            }
        }).then(function () { });
    }
    generateDocumentAndGetLink(template, sourceClass, sourceId, format = "pdf", mandated = false) {
        const payload = {
            template,
            sourceClass,
            sourceId,
            format,
            mandated
        };
        const generator = window.Router.generate('helper_generate_document', payload);
        return $.ajax({
            type: "GET",
            async: true,
            url: generator,
            success: (data) => data
        });
    }
}
window.dg = new DocumentGenerator();
